<template>

  <head-panel>
    <template v-slot:body>
    Counter
    <router-link to="/counter/create">
      <button class="btn btn-primary btn-sm btn-create-top ms-3 " role="button">Add new counter
      </button>
    </router-link>
    </template>
  </head-panel>


  <div class="wrapper">



    <div class="ps-4 pe-4">


    <div class="row">

      <div class="col-xxl-2 col-xl-3 col-lg-5 col-md-6  mb-3" v-if="!counters.length">
        <div class="card">
          <div class="card-header ">
            <span class="text-truncate"> Create new counter</span>


          </div>
          <div class="card-body">

            <p>Text of induce to action. It is a long established fact that a reader will be distracted by the readable
              content of a page when looking at its layout. </p>

            <router-link to="/counter/create">Create new counter</router-link>

          </div>
        </div>
      </div>


      <div class="col-xxl-4 col-xl-5 col-lg-6 col-md-6 mb-3" v-for="counter in counters" :key="counter.id">


        <div class="stats-wrapper counter">

          <div class="card-header ">



            <span class="text-truncate"> {{ counter.name }}</span>


            <el-dropdown class="float-end" trigger="click">
            <span class="el-dropdown-link">
              <i class='bx bx-dots-vertical-rounded '></i>
            </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <router-link :to="{ path: '/counter/update/'+counter.id}" class="toggle-action">Edit</router-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button text @click="deleteConfirm(counter.id)" class="text-danger toggle-action">Delete
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>


          </div>



          <div class="mb-4 d-flex">
            <div class="pe-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box" viewBox="0 0 16 16">
                <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
              </svg>
            </div>
            <div class="">
              <h6 class=" text-800 mb-1">Counter ID <i class='bx bx-copy '></i></h6>
              <p class="fs--1 text-600 mb-0">{{ counter.id }}</p>
            </div>
          </div>

          <div class="mb-4 d-flex">
            <div class="pe-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-flag" viewBox="0 0 16 16">
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
              </svg>
            </div>
            <div class="">
              <h6 class="text-800 mb-1">Site</h6>
              <p class="fs--1 text-600 mb-0">{{ counter.site }}</p>
            </div>
          </div>


          <div class="mb-4 d-flex">
            <div class="pe-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-database" viewBox="0 0 16 16">
                <path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z"/>
              </svg>
            </div>
            <div class="">
              <h6 class="text-800 mb-1">Index URL</h6>
              <p class="fs--1 text-600 mb-0 text-truncate">{{ counter.indexFileUrl }}</p>
            </div>
          </div>



          <div class=" d-flex">
            <div class="pe-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-code-slash" viewBox="0 0 16 16">
                <path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z"/>
              </svg>
            </div>
            <div class="">
              <h6 class="text-800 mb-1">Counter code <i class='bx bx-copy'></i></h6>
              <p class="text-truncate">

                window.searchConfig = window.searchConfig || [];
                function sConf() {
                searchConfig.push(arguments);
                }
                sConf('external_search_url', 'https://www.google.com.ua/search?q=');


              </p>
            </div>
          </div>


          <div class="mb-4 d-flex">
            <div class="pe-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
              </svg>
            </div>
            <div class="">
              <h6 class="text-800 mb-1">Vocabularies</h6>
              <p class="fs--1 text-600 mb-0">{{counter.vocabularies.map(e=> e.name)}}</p>
            </div>
          </div>


        </div>





      </div>

    </div>


  </div>


  </div>
  <confirm-dialog ref="confirmDialogue"/>
</template>

<script>

import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialog from "../../components/ConfirmDialog";

export default {
  name: "Counters",
  components: {HeadPanel, ConfirmDialog},
  created() {
    this.fetch();
  },
  data() {
    return {
      counters: []
    }
  },
  methods: {
    fetch: function () {
      Http.get(process.env.VUE_APP_INDEX_SERVICE + '/v1/counter')
          .then(res => {
            this.counters = res.content;
          });
    },
    deleteConfirm: function (id) {

      this.$refs.confirmDialogue.show({
        title: 'Warning',
        message: 'It will permanently delete the counter. Continue?',
        okButton: 'OK',
        cancelButton: 'Cancel',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_INDEX_SERVICE + '/v1/counter/' + id)
              .then(() => this.fetch());
      })
    }
  }
}
</script>

<style scoped>

.card-header {
  background-color: #fff;
  border-bottom: 1px solid rgba(84,84,84,.12);
  margin-bottom: 14px;
  padding-top: 0;
}

.text-truncate {
  max-width: 250px;
}


</style>