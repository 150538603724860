<template>
  <router-view name="LeftSidebar"/>
  <section class="home">
      <router-view/>
  </section>
</template>

<script>

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

export default {
  name: 'App',
}
</script>
<style>
@import './assets/css/theme.css';
@import './assets/css/custom.css';
@import 'https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css';
</style>