<template>
  <div class="dropdown">
    <button class="btn dropdown-toggle " type="button" data-bs-toggle="dropdown">
      <span class="text-truncate" v-if="selected"> {{ selected.value }} - {{ selected.name }}</span>
    </button>
    <div class="dropdown-menu shadow">

      <form class="px-2">
        <div class="mb-2">
          <input type="text" class="form-control" placeholder="Search..." v-model="search">
        </div>
      </form>

      <ul>
        <li v-for="option in data" :key="option.value" v-on:click="select(option.value)">
          {{ option.value }}<br/>{{ option.name }}
        </li>
      </ul>

    </div>
  </div>
</template>

<script>

import Http from "../lib/Http";

export default {
  name: "SelectCounter",
  created() {
    this.fetchCounter();
  },
  data() {
    return {
      search: '',
      selected: null,
      data: [],
      options: [],
    }
  },
  watch: {
    search: function (val) {
      this.data = [];
      for (let option of this.options)
        if (option.name.toLowerCase().includes(val.toLowerCase()) || option.value.toLowerCase().includes(val.toLowerCase()))
          this.data[this.data.length] = option;
    }
  },
  methods: {
    fetchCounter: function () {
      Http.get(process.env.VUE_APP_INDEX_SERVICE + '/v1/counter')
          .then(res => {
            this.data = this.options = res.content.map(v => {
              return {
                name: v.name,
                value: v.id
              };
            });

            if (this.options.length) {
              const current = this.getCounter(this.$cookies.get('counter'));
              this.selected = current ?? this.options[0];
              this.select(this.selected.value);
            } else {
              this.$router.push('/counters')
            }
          });
    },
    select: function (val) {
      this.selected = this.getCounter(val);
      this.$cookies.set('counter', this.selected.value);
      this.$emit('onChange', val);
    },
    getCounter: function (val) {
      return this.options.find(option => {
        if (option.value === val)
          return option;
      });
    }
  }
}
</script>

<style scoped>

.dropdown {
  display: inline-block;
}

button.dropdown-toggle {
  border: 1px solid rgba(0, 0, 0, .125);
  background-color: #fff;
  color: black;
  margin-bottom: 0;
  text-align: left;
  padding: 4px 10px;
  margin-top: 0;
}

button.dropdown-toggle span {
  width: 285px;
  float: left;
}

.dropdown-toggle::after {
  float: right;
  color: #999898;
  margin-top: 10px;
}

.dropdown-menu {
  width: 100%;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.dropdown-menu ul li {
  cursor: pointer;
  padding: 8px 14px;
  border-top: 1px solid rgba(84, 84, 84, 0.04);
}

.dropdown-menu ul li:hover {
  background-color: #ecf5ff;
}

</style>