<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 offset-xl-4 offset-lg-3 offset-md-3 offset-sm-0 ">

        <div class="row align-items-center mb-5 ">
          <div class="col"><h1>Sign In</h1></div>
          <div class="col text-end"> New in search?
            <router-link to="/registration">Let`s register.</router-link>
          </div>
        </div>
        <div class="alert alert-danger" :key="error" v-for="error of errors">{{ error }}</div>
        <form @submit.prevent="submit">
          <div class="mb-3">

            <div class="form-floating mb-3">
              <input type="email" class="form-control" :class="{ 'is-invalid' : v$.form.username.$error}"
                     placeholder="name@example.com" v-model="form.username">
              <label>Email address</label>
              <div class="form-text">We'll never share your email with anyone else.</div>
              <div class="invalid-feedback" v-for="error of v$.form.username.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>

          </div>
          <div class="mb-3">

            <div class="form-floating mb-3">
              <input type="password" class="form-control" placeholder="password"
                     :class="{ 'is-invalid' : v$.form.password.$error}" v-model="form.password">
              <label>Password</label>
              <div class="invalid-feedback" v-for="error of v$.form.password.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>

          </div>
          <router-link to="/forgot-password" class="forgot-link">Forgot password?</router-link>
          <div class="d-grid gap-2">
            <button type="submit" class="btn btn-primary btn-lg">Sign In</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from 'vue-router'
import {useVuelidate} from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
import Http from "../../lib/Http";

export default {
  name: "Login",
  data() {
    return {
      errors: [],
      registered: useRoute().query.register,
      form: {
        username: 'revardy@gmail.com',
        password: 'test12'
      }
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        username: {required, email},
        password: {required}
      }
    }
  },
  methods: {
    submit: function () {

      this.v$.form.$touch();

      if (this.v$.form.$error) return;

      this.errors = [];

      Http.post('/auth', {username: this.form.username, password: this.form.password})
          .then((res) => {
            Http.getToken().setAccessToken(res.access_token).setRefreshToken(res.refresh_token);
            this.$router.push('/')
          })
          .catch((res) => {
            if (res.message)
              this.errors.push(res.message);
            if (res.errors)
              res.errors.map(v => this.errors.push(v.message));
          });
    },

  }
}
</script>

<style scoped>
.container {
  margin-top: 200px;
}

a {
  text-decoration: none;

}

form {
  position: relative;
}

.forgot-link {
  font-size: 13px;
  margin-top: -48px;
  z-index: 99999;
  position: absolute;
  right: 10px;
}

.forgot-link:hover{

}
</style>