<template>
  <ul class="nav nav-pills">
    <li class="nav-item" >
      <router-link class="nav-link" :to="'/analytics/keywords'" v-bind:class="$route.path.startsWith('/analytics')?'active':''">Keywords</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tab",
  props: {
  },
}
</script>

<style scoped>

</style>