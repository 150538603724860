import { createRouter, createWebHashHistory} from "vue-router";
import synonym from "./Synonym"
import Sidebar from '../components/Sidebar.vue'
import Index from "../views/main/Index"
import Schedule from "../views/schedule"
import Counters from "../views/counter/Index";
import CounterForm from "../views/counter/Form";
import Login from "../views/auth/Login";
import Keywords from "../views/analytics/Keywords.vue"

const routes = [
    {
        path: "/",
        name: "Index",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/analytics/keywords",
        name: "Keywords",
        components: {
            default: Keywords,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/counters",
        name: "Counters",
        components: {
            default: Counters,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/counter/update/:id",
        name: "Counter update",
        components: {
            default: CounterForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/counter/create",
        name: "Counter create",
        components: {
            default: CounterForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/schedule",
        name: "Schedule",
        components: {
            default: Schedule,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/oauth2/token",
        name: "Login",
        component: Login
    },
    ...synonym
];

const router = createRouter({
    history: createWebHashHistory(),
    // history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router