<template>

  <div class="block">
    <el-date-picker
        v-model="model"
        type="daterange"
        unlink-panels
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        :shortcuts="shortcuts"
    />
  </div>

</template>

<script>

import moment from 'moment'

export default {
  name: "DateRange",
  components: {},
  props: {
    dateRange: Array
  },
  created: function () {
    this.moment = moment;
  },
  watch: {
    model(val) {
      this.$emit('onChange', val);
    }
  },
  data() {
    return {
      model: [this.dateRange ? this.dateRange[0] : new Date(), this.dateRange ? this.dateRange[1] : new Date()],
      shortcuts : [
        {
          text: 'Last week',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: 'Last month',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ]
      // model: [moment().subtract(1, 'month').toDate(), moment().toDate()]
    };
  },
}
</script>

<style scoped>
.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}

.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.demo-date-picker .block:last-child {
  border-right: none;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
</style>