<template>
  <nav class="sidebar close">
    <header>
      <div class="image-text">
        <span class="image logo">

          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>layer</title>
              <desc>Created with sketchtool.</desc>
              <g id="production" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="layer" fill="#000000" fill-rule="nonzero">
                      <path
                          d="M6,8 L12,11 L18,8 L12,5 L6,8 Z M12.4888445,3.11627134 L20.4820687,7.19232961 C20.9680351,7.44014296 21.1431257,8.00264173 20.8731443,8.44870577 C20.7818575,8.59953008 20.6463849,8.72387911 20.4820687,8.80767039 L12.4888445,12.8837287 C12.1848309,13.0387571 11.8151691,13.0387571 11.5111555,12.8837287 L3.51793134,8.80767039 C3.03196486,8.55985704 2.85687434,7.99735827 3.12685572,7.55129423 C3.21814251,7.40046992 3.35361512,7.27612089 3.51793134,7.19232961 L11.5111555,3.11627134 C11.8151691,2.96124289 12.1848309,2.96124289 12.4888445,3.11627134 Z M12.4888445,16.8817236 C12.1848309,17.0394255 11.8151691,17.0394255 11.5111555,16.8817236 L3.51793134,12.7353739 C3.03196486,12.483287 2.85687434,11.911088 3.12685572,11.4573316 C3.21814251,11.3039064 3.35361512,11.1774129 3.51793134,11.0921767 C3.75485908,10.9692744 4.03532731,10.9692744 4.27225504,11.0921767 L11.5111555,14.8472337 C11.8151691,15.0049356 12.1848309,15.0049356 12.4888445,14.8472337 L19.727745,11.0921767 C19.9646727,10.9692744 20.2451409,10.9692744 20.4820687,11.0921767 C20.9680351,11.3442636 21.1431257,11.9164626 20.8731443,12.370219 C20.7818575,12.5236442 20.6463849,12.6501377 20.4820687,12.7353739 L12.4888445,16.8817236 Z M12.4888445,20.8817236 C12.1848309,21.0394255 11.8151691,21.0394255 11.5111555,20.8817236 L3.51793134,16.7353739 C3.03196486,16.483287 2.85687434,15.911088 3.12685572,15.4573316 C3.21814251,15.3039064 3.35361512,15.1774129 3.51793134,15.0921767 C3.75485908,14.9692744 4.03532731,14.9692744 4.27225504,15.0921767 L11.5111555,18.8472337 C11.8151691,19.0049356 12.1848309,19.0049356 12.4888445,18.8472337 L19.727745,15.0921767 C19.9646727,14.9692744 20.2451409,14.9692744 20.4820687,15.0921767 C20.9680351,15.3442636 21.1431257,15.9164626 20.8731443,16.370219 C20.7818575,16.5236442 20.6463849,16.6501377 20.4820687,16.7353739 L12.4888445,20.8817236 Z"
                          id="Shape"></path>
                  </g>
              </g>
          </svg>

        </span>
        <div class="text logo-text">
          <span class="name">search</span>
          <!--          <span class="profession">Console</span>-->
        </div>
      </div>

      <i class='bx bx-chevron-right toggle'></i>

    </header>

    <div class="menu-bar">
      <div class="menu">

        <!--        <li class="search-box">-->
        <!--          <i class='bx bx-search icon'></i>-->
        <!--          <input type="text" placeholder="Search...">-->
        <!--        </li>-->

        <ul class="menu-links">
          <li class="nav-link">
            <router-link to="/">
               <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-airplane" viewBox="0 0 16 16">
  <path
      d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Zm.894.448C7.111 2.02 7 2.569 7 3v4a.5.5 0 0 1-.276.447l-5.448 2.724a.5.5 0 0 0-.276.447v.792l5.418-.903a.5.5 0 0 1 .575.41l.5 3a.5.5 0 0 1-.14.437L6.708 15h2.586l-.647-.646a.5.5 0 0 1-.14-.436l.5-3a.5.5 0 0 1 .576-.411L15 11.41v-.792a.5.5 0 0 0-.276-.447L9.276 7.447A.5.5 0 0 1 9 7V3c0-.432-.11-.979-.322-1.401C8.458 1.159 8.213 1 8 1c-.213 0-.458.158-.678.599Z"/>
</svg>
               </span>
              <span class="text nav-text">Dashboard</span>
            </router-link>
          </li>

          <li class="nav-link">
            <router-link to="/counters">
               <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-database"
                       viewBox="0 0 16 16">
                    <path
                        d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z"/>
                  </svg>
               </span>
              <span class="text nav-text">Counters</span>
            </router-link>
          </li>

          <li class="nav-link">
            <router-link to="/vocabulary">
               <span class="icon">
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
  <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
</svg>
               </span>
              <span class="text nav-text">Synonym</span>
            </router-link>
          </li>

          <li class="nav-link">
            <router-link to="/analytics/keywords">
               <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-graph-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"/>
                  </svg>
               </span>
              <span class="text nav-text">Analytics</span>
            </router-link>
          </li>

          <li class="nav-link">
            <router-link to="/schedule">
               <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-clock-history" viewBox="0 0 16 16">
                    <path
                        d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                    <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                    <path
                        d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                  </svg>
               </span>
              <span class="text nav-text">Index</span>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="bottom-content">
        <li class="">
          <a href="#" v-on:click="logout()">
            <i class='bx bx-log-out icon'></i>
            <span class="text nav-text">Logout</span>
          </a>
        </li>

        <li class="mode">
          <div class="sun-moon">
            <i class='bx bx-moon icon moon'></i>
            <i class='bx bx-sun icon sun'></i>
          </div>
          <span class="mode-text text">Dark mode</span>

          <div class="toggle-switch">
            <span class="switch"></span>
          </div>
        </li>

      </div>
    </div>

  </nav>
</template>

<script>
import Http from "../lib/Http";

export default {
  name: "sidebar",
  mounted() {
    this.init()
  },
  methods: {
    logout: function () {
      Http.logout();
      return false;
    },
    init: function () {

      const body = document.querySelector('body'),
          sidebar = body.querySelector('nav'),
          toggle = body.querySelector(".toggle"),
          // searchBtn = body.querySelector(".search-box"),
          modeSwitch = body.querySelector(".toggle-switch"),
          modeText = body.querySelector(".mode-text");


      toggle.addEventListener("click", () => {
        sidebar.classList.toggle("close");
      })

      // searchBtn.addEventListener("click", () => {
      //   sidebar.classList.remove("close");
      // })

      modeSwitch.addEventListener("click", () => {
        body.classList.toggle("dark");

        if (body.classList.contains("dark")) {
          modeText.innerText = "Light mode";
        } else {
          modeText.innerText = "Dark mode";

        }
      });

    },
  }
}

</script>

<style>
@import '../assets/css/sidebar.css';

.sidebar li.mode {
  display: none;
}
</style>

