<template>
  <div class="head-title">Vocabulary
    <router-link to="/vocabulary">
      <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button"><i class='bx bx-arrow-back icon'></i>
        Back
      </button>
    </router-link>


    <a @click="remove" class="btn btn-danger btn-sm btn-remove float-end" v-if="vocabulary">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
           viewBox="0 0 16 16">
        <path
            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
        <path fill-rule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
      </svg>
      Remove vocabulary
    </a>

  </div>

  <div class="wrapper">

    <div class="ps-4 pe-4">


      <div class="row">
        <div class="col-xxl-4 col-xl-7 col-lg-10 col-md-12">
          <div class="card">
            <div class="card-body">

              <div class="alert alert-danger" v-if="errors.length">
                <div v-for="error of errors" :key="error">{{ error.message }}</div>
              </div>
              <form @submit.prevent="submit">


                <div class="mb-3">
                  <label class="form-label">Vocabulary name</label>
                  <input type="text"
                         class="form-control"
                         :class="{ 'is-invalid' : v$.form.name.$error}"
                         v-model="form.name">
                  <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>


                <button type="submit" class="btn btn-primary">Save</button>
              </form>
            </div>
          </div>


        </div>
      </div>

    </div>

  </div>

  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>

import {required} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import Http from "../../lib/Http";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'

export default {
  name: "VocabularyUpdate",
  components: {
  ConfirmDialogue
  },
  created() {
    if (this.$route.params.id)
      this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {required}
      }
    }
  },
  data() {
    return {
      errors: [],
      vocabulary: null,
      form: {
        name: null,
      },
    }
  },
  methods: {
    fetch: function () {

      Http.get(process.env.VUE_APP_INDEX_SERVICE + '/v1/vocabulary/' + this.$route.params.id)
          .then((res) => {
            this.vocabulary = res.content;
            this.form = res.content;
          });
    },
    submit() {
      const form = this.v$.form;

      form.$touch();

      if (form.$error) return

      const data = {
        name: this.form.name,
      };

      let request = this.$route.params.id === undefined ?
          Http.post(process.env.VUE_APP_INDEX_SERVICE + '/v1/vocabulary', data) :
          Http.put(process.env.VUE_APP_INDEX_SERVICE + '/v1/vocabulary/' + this.$route.params.id, data);

      request.then(() => {
        this.$router.push('/vocabulary')
      }).catch((e) => {
        this.$refs.alert.danger(e.content[0].message);
      });
    },
    remove: function () {
      this.$refs.confirmDialogue.show({
        title: 'Removing',
        message: 'Remove vocabulary and all it data?',
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_INDEX_SERVICE + "/v1/vocabulary/" + this.vocabulary.uuid)
              .then(() => {
                this.$router.replace("/vocabulary");
              });
      })
    },
  }
}
</script>

<style scoped>

</style>