<template>
  <head-panel>
    <template v-slot:body>
      Vocabularies

      <router-link to="/vocabulary/create">
        <button class="btn btn-primary btn-sm btn-create-top ms-3 " role="button">Add new vocabulary
        </button>
      </router-link>

    </template>
  </head-panel>

  <div class="wrapper">


    <table class="table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Terms</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in vocabularies" :key="item.id">
        <td>{{ item.name }}</td>
        <td>{{ item.counts }}</td>
        <td class="actions">
          <router-link :to="'/vocabulary/'+item.uuid">Edit</router-link>
          <router-link :to="'/vocabulary/'+item.uuid+'/synonym'">List terms</router-link>
        </td>
      </tr>
      </tbody>
    </table>


    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>


  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from "../../components/ConfirmDialog";
import Pagination from "../../components/Pagination.vue";


export default {
  name: "Schedule",
  components: {
    HeadPanel, ConfirmDialogue, Pagination,
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      counter: null,
      vocabularies: [],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetch: function () {
      Http.get(process.env.VUE_APP_INDEX_SERVICE + '/v1/vocabulary')
          .then(res => this.vocabularies = res.content);
    },
    run: function () {

      this.$refs.confirmDialogue.show({
        title: 'Schedule index',
        message: 'Do you really want Schedule import ?',
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http.post('/syn/v1/schedule/run?counter=' + this.counter)
              .then(() => this.fetch());
      })
    },
  },
}
</script>

<style scoped>

</style>