<template>
  <div class="head-title">
    <slot name="body">
      Home
    </slot>
  </div>
</template>

<script>
export default {
  name: "HeadPanel",
}
</script>

<style scoped>

</style>