<template>
  <head-panel>
    <template v-slot:body>
      Schedule

      <button class="btn btn-primary btn-sm btn-create-top ms-3 " role="button" v-if="meta" :disabled="!canRun"
              @click="run()">
        <span v-if="canRun">Reindex now ({{ meta.manualRunCount }}/{{ maxManualRun }})</span>
        <span v-else>Reindexing..</span>
      </button>

      <counter @onChange="changeCounter" class="counter"/>

    </template>
  </head-panel>

  <div class="wrapper">

    <div class="ps-4 pe-4">

      <div class="stats-wrapper mb-3">
        <div style="height: 300px;">
          <v-chart class="chart" :option="chart"/>
        </div>
      </div>


      <div class="stats-wrapper">

        <table class="table">
          <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Run after</th>
            <th>Execution time</th>
            <th>Documents count</th>
            <th>Errors</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in schedules" :key="item.id">
            <td>{{ item.counter }}</td>
            <td>{{ item.status }}</td>
            <td>{{ dateTime(item.runAt) }} ({{ item.runType }})</td>
            <td>{{ item.executionTime }} sec</td>
            <td>{{ item.readCount }}</td>
            <td>{{ item.errorCount }}</td>
          </tr>
          </tbody>
        </table>

      </div>

    </div>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import Counter from "../../components/Counter";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from "../../components/ConfirmDialog";
import moment from 'moment';


export default {
  name: "Schedule",
  components: {
    Counter, HeadPanel, ConfirmDialogue
  },
  created() {
    this.autoUpdate();
  },
  beforeUnmount() {
    clearInterval(this.autoUpdateTimer);
  },
  computed: {
    canRun() {
      const last = this.schedules && this.schedules[0] ? this.schedules[0] : null;
      return !last || ((last.runType !== 'manual' || last.status !== 'new') && this.meta.manualRunCount < this.maxManualRun);
    }
  },
  data() {
    return {
      counter: null,
      autoUpdateTimer: null,
      meta: null,
      schedules: [],
      chart: null,
      maxManualRun: 50,
      autoUpdateSec: 30
    }
  },
  methods: {
    changeCounter: function (counter) {
      this.counter = counter;
      this.fetch();
    },
    fetch: function () {
      Http.get('/ind/v1/schedule/statistic?counter=' + this.counter)
          .then(res => this.schedules = this._mapResult(res.content))
          .then(() => this.chart = this._chartData(this.schedules))
          .then(() => this.fetchMeta());
    },
    fetchMeta: function () {
      Http.get('/ind/v1/schedule/meta?counter=' + this.counter)
          .then(res => {
            this.meta = res.content;
          })
    },
    run: function () {

      this.$refs.confirmDialogue.show({
        title: 'Schedule index',
        message: 'Do you really want Schedule import ?',
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http.post('/ind/v1/schedule/run?counter=' + this.counter)
              .then(() => this.fetch());
      })
    },
    autoUpdate: function () {
      clearInterval(this.autoUpdateTimer);
      this.autoUpdateTimer = setInterval(() => this.fetch(), this.autoUpdateSec * 1000);
    },
    dateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    },
    _mapResult: function (data) {
      return data
          .map((item) => {
            let startTime = new Date(item.createdAt);
            let endTime = new Date(item.updatedAt);
            let difference = endTime.getTime() - startTime.getTime();
            item.executionTime = Math.round(difference / 1000);
            item.errorCount = item.readCount - item.writeCount;
            item.readCount = item.readCount ?? 0;
            return item;
          });
    },
    _chartData: function (schedules) {
      const xAxisData = [];
      const seriesBarData = [];
      const seriesLineData = [];

      schedules
          .map(schedule => schedule)
          .forEach(schedule => {
            let date = new Date(schedule.createdAt);
            xAxisData.push(date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds());
            seriesBarData.push(schedule.readCount);
            seriesLineData.push(schedule.readCount - schedule.writeCount);
          });

      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          // width: '100%',
          containLabel: true
        },
        xAxis: [
          {
            // interval: 100,
            // min: 5,
            max: 20,
            type: 'category',
            data: xAxisData
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            }
          },
          // {
          //   type: 'value'
          // }
        ],
        series: [
          {
            name: 'Errors',
            type: 'line',
            // yAxisIndex: 1,
            // tooltip: {
            //   valueFormatter: function (value) {
            //     return value + ' °C';
            //   }
            // },
            lineStyle: {
              color: '#f5803e',
              type: 'dashed'
            },
            itemStyle: {
              color: '#f5803e'
            },
            data: seriesLineData
          },
          {
            name: 'Documents',
            type: 'bar',
            barWidth: 16,
            // showBackground: true,
            // backgroundStyle: {
            //   color: 'rgba(180, 180, 180, 0.2)'
            // },
            itemStyle: {
              color: '#248afd',
              borderWidth: 0,
              borderColor: "#e8eef6",
              borderRadius: [4, 4, 0, 0]
            },
            emphasis: {
              focus: 'series'
            },
            data: seriesBarData
          },
        ]
      };
    }
  },
}
</script>

<style scoped>

</style>