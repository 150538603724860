import {createApp} from 'vue'
import App from './App.vue'
import Router from './router/Index'

import ECharts from 'vue-echarts'
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers";
import {BarChart, LineChart} from "echarts/charts";
import {TooltipComponent, LegendComponent, GridComponent} from "echarts/components";

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Http from "./lib/Http";
import VueCookies from 'vue-cookies'
import OAuth from './lib/OAuth2';


use([
    CanvasRenderer,
    BarChart,
    LineChart,
    GridComponent,
    TooltipComponent,
    LegendComponent
]);


if (location.pathname === "/" && Http.getQueryParameter('code')) {
    const oauth = new OAuth(process.env.VUE_APP_OAUTH_URL, process.env.VUE_APP_OAUTH_CLIENT_ID, process.env.VUE_APP_OAUTH_REDIRECT_URL);
    oauth.requestToken();
    location.href = "/";
} else {
    const app = createApp(App);
    app.use(ElementPlus)
    app.use(VueCookies, {expire: '7d'})
    app.component('v-chart', ECharts)
    app.use(Router).mount('#app');
}
