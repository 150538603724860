
import Sidebar from '../components/Sidebar.vue'
import Vocabularies from "../views/synonym/Vocabularies"
import Synonyms from "../views/synonym/Synonyms"
import VocabularyForm from "../views/synonym/VocabularyForm.vue"
import SynonymForm from "../views/synonym/SynonymForm.vue"

export default [
    {
        path: "/vocabulary",
        name: "Vocabularies",
        components: {
            default: Vocabularies,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/vocabulary/create",
        name: "VocabularyCreate",
        components: {
            default: VocabularyForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/vocabulary/:id",
        name: "VocabularyUpdate",
        components: {
            default: VocabularyForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/vocabulary/:vid/synonym",
        name: "Synonyms",
        components: {
            default: Synonyms,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/vocabulary/:vid/synonym/create",
        name: "SynonymCreate",
        components: {
            default: SynonymForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/synonym/:id",
        name: "SynonymUpdate",
        components: {
            default: SynonymForm,
            LeftSidebar: Sidebar
        }
    }
];