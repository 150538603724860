
let timer = {default: null};

export function debounce(f, t, n = 'default') {

    return function (args) {
        clearTimeout(timer[n]);
        timer[n] = setTimeout(() => f(args), t);
    }
}


export function groupBy(array, key) {
    return array.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}


export function randomString(length) {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}