<template>
  <head-panel>
    <template v-slot:body>Analytics
      <counter @onChange="changeCounter" class="counter"/>
    </template>
  </head-panel>

  <div class="wrapper">

    <tab/>

    <hr/>

    <div class="ps-4 pe-4">

      <div class="row">
        <div class="col-auto">
          <date-range @onChange="changeDateRange" :date-range="dateRange"/>
        </div>
      </div>

      <div class="stats-wrapper mt-2">

        <table class="table">
          <thead>
          <tr>
            <th>Keyword</th>
            <th>Count</th>
            <th>Progression</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.keyword">
            <td>{{ item.keyword }}</td>
            <td>{{ item.count }}</td>
            <td>{{ item.growing }}%</td>
            <td></td>
          </tr>
          </tbody>
        </table>

      </div>


    </div>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>

</template>

<script>
import Http from "../../lib/Http";
import Counter from "../../components/Counter";
import DateRange from "../../components/DateRange";
import moment from 'moment'
import HeadPanel from "../../components/HeadPanel";
import Pagination from "../../components/Pagination";
import Tab from "./Tab";

export default {
  name: "Index",
  components: {
    Counter,
    DateRange,
    Tab,
    Pagination,
    HeadPanel
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetchKeywords(this.counter);
    },
  },
  data() {
    return {
      counter: null,
      items: [],
      dateRange: [moment().subtract(1, 'month').toDate(), moment().toDate()],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetchKeywords: function (counter) {
      const query = this.$route.query;
      let from = query.from ?? moment().subtract(1, 'M').format("YYYY-MM-DD");
      let to = query.to ?? moment().format("YYYY-MM-DD");

      let prevFrom = moment().subtract(2, 'M').format("YYYY-MM-DD");
      let prevTo = moment().subtract(1, 'M').format("YYYY-MM-DD");

      const search = {
        counter: counter,
        from: from,
        to: to
      }

      if (this.$route.query.page)
        search.page = this.$route.query.page;


      let currentMonthResult = [];

      Http.get('/als/v1/analytics/keyword?' + new URLSearchParams(search).toString())
          .then(res => {
            currentMonthResult = res;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          })
          .then(() => Http.get('/als/v1/analytics/keyword?counter=' + counter + '&from=' + prevFrom + '&to=' + prevTo))
          .then(res => {
            this.items = this.merge([currentMonthResult.content, res.content]);
          });
    },
    changeCounter: function (counter) {
      this.counter = counter;
      this.fetchKeywords(this.counter)
    },
    changeDateRange: function (range) {
      this.dateRange = range;
      this.fetchKeywords(this.counter)
    },
    merge: function (data) {

      let result = [];
      let indexed = {};

      for (let item of data[1])
        indexed[item.keyword] = item;

      for (let item of data[0]) {
        const percent = indexed[item.keyword] !== undefined ? this.percent(item.count, indexed[item.keyword].count) : 0;
        result.push({count: item.count, keyword: item.keyword, growing: percent.toPrecision(1)});
      }
      return result;
    },
    percent: function (newVal, oldVal) {
      return newVal > oldVal ?
          (newVal - oldVal) / oldVal :
          ((oldVal - newVal) / oldVal) * -1;
    }
  }
};
</script>

<style scoped>
x-vue-echarts {
  margin-bottom: 40px;
}
</style>